body {
    /*margin-top: 20px;
    background: #f7f8fa;*/
}

.avatar-xxl {
    height: 7rem;
    width: 7rem;
}

.card {
    /* padding-bottom: 20px;*/
    margin-bottom: 20px;
    -webkit-box-shadow: 0 2px 3px #eaedf2;
    box-shadow: 0 2px 3px #eaedf2;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #038edc;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 500;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}

.bg-soft-primary {
    background-color: rgba(3, 142, 220, 0.15) !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.nav-tabs-custom .nav-item .nav-link.active {
    color: #038edc;
}

.nav-tabs-custom .nav-item .nav-link {
    border: none;
}

.nav-tabs-custom .nav-item .nav-link.active {
    color: #038edc;
}

.avatar-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 12px;
}

.border-end {
    border-right: 1px solid #eff0f2 !important;
}

.d-inline-block {
    display: inline-block !important;
}

.badge-soft-danger {
    color: #f34e4e;
    background-color: rgba(243, 78, 78, 0.1);
}

.badge-soft-warning {
    color: #f7cc53;
    background-color: rgba(247, 204, 83, 0.1);
}

.badge-soft-success {
    color: #51d28c;
    background-color: rgba(81, 210, 140, 0.1);
}

.avatar-group .avatar-group-item {
    margin-left: -14px;
    border: 2px solid #fff;
    border-radius: 50%;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.avatar-sm {
    height: 2rem;
    width: 2rem;
}

.nav-tabs-custom .nav-item {
    position: relative;
    color: #343a40;
}

.nav-tabs-custom .nav-item .nav-link.active:after {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.nav-tabs-custom .nav-item .nav-link::after {
    content: "";
    background: #038edc;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -2px;
    -webkit-transition: all 250ms ease 0s;
    transition: all 250ms ease 0s;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.badge-soft-secondary {
    color: #74788d;
    background-color: rgba(116, 120, 141, 0.1);
}

.badge-soft-secondary {
    color: #74788d;
}

.work-activity-custom {
    position: relative;
    color: #74788d;
    padding-left: 5.5rem;
}

.work-activity-custom::before {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    left: 66px;
    border-left: 1px solid rgba(3, 142, 220, 0.25);
}

.work-activity-custom .work-item-custom {
    position: relative;
    border-bottom: 2px dashed #eff0f2;
    margin-bottom: 14px;
}

.work-activity-custom .work-item-custom:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.work-activity-custom .work-item-custom::after,
.work-activity-custom .work-item-custom::before {
    position: absolute;
    display: block;
}

.work-activity-custom .work-item-custom::before {
    content: attr(data-date);
    left: -157px;
    top: -3px;
    text-align: right;
    font-weight: 500;
    color: #74788d;
    font-size: 12px;
    min-width: 120px;
}

.work-activity-custom .work-item-custom::after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: -26px;
    top: 3px;
    background-color: #fff;
    border: 2px solid #038edc;
}

.list-unstyled-custom {
    list-style: none;
}

.container-profile {
    --bs-gutter-x: 0rem;
}

/* Estilo para el mapa */
#map {
    width: 100%;
    height: 400px;
}


.loading_content {
    left: 50%;
    position: absolute;
    top: 50%;
    text-align: center;
    max-width: 250px;
    margin-left: -125px;
    max-height: 200px;
    margin-top: -100px;
}

.loading i {
    font-size: 100px;
}

.loading_content span {
    font-size: 20px;
    color: #5f5e5e;
    font-weight: 1000;
}

.loading {
    opacity: 0.6;
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: none;
    margin: 0;
    padding: 0;
    z-index: 1000;
}
